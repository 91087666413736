import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik } from "formik"
import * as Yup from "yup"

import Button from "../ui/Button"

import LoadingSpinner from "../ui/LoadingSpinner"
import { useMedusaCheckout } from "../medusa-checkout-builder"
import { formatPrices } from "../../utils/prices"
import { useTranslation } from "react-i18next"
import {
  CartBody,
  CartContainer,
  LineItem,
  Row,
  Section,
} from "../swap-checkout"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import AddressSection from "../new-ui/address-section"
import DeliveryStep from "../checkout/delivery-step"
import PaymentStep from "../checkout/payment-step"

const PAYPAL_CLIENT_ID =
  process.env.GATSBY_PAYPAL_CLIENT_ID ||
  "AZkdvdsRMCeX27ZlDkHvqpYpekGtH6T2flGXqIfpV2MH6NIiLcbA9AnCCv8--nFT7cF59E31XijjFLvN"
const PAYPAL_INTENT = process.env.PAYPAL_INTENT || "authorize"

const PaymentLinkCheckout = () => {
  const { t } = useTranslation()

  const AddressSchema = Yup.object().shape({
    first_name: Yup.string().required(t("required")).nullable(),
    last_name: Yup.string().required(t("required")).nullable(),
    address_1: Yup.string().required(t("required")).nullable(),
    address_2: Yup.string().nullable(),
    city: Yup.string().required(t("required")).nullable(),
    province: Yup.string().nullable(),
    postal_code: Yup.string().required(t("required")).nullable(),
    country_code: Yup.string().required(t("required")).nullable(),
    phone: Yup.string().required(t("required")).nullable(),
  })

  const {
    cart,
    hasEmail,
    hasShippingAddress,
    hasShipping,
    update,
    addShippingMethod,
    processingDetails,
    processingShipping,
  } = useMedusaCheckout()

  const [step, setStep] = useState("shipping_method")

  useEffect(() => {
    if (cart.id && cart.completed_at !== null) {
      navigate(`/payment-links/done?pct=${cart.id}`)
    }

    switch (true) {
      case hasEmail && hasShippingAddress && hasShipping:
        setStep("payment")
        break
      case hasEmail && hasShippingAddress:
        setStep("shipping_method")
        break
      default:
        setStep("address")
        break
    }
  }, [cart, hasEmail, hasShipping, hasShipping])

  const handleAddressSubmit = async (values) => {
    delete values.email
    update({ shipping_address: values })
  }

  const handlePaymentCompleted = () => {
    navigate(`/payment-links/done?pct=${cart.id}`)
  }

  const handleDeliverySubmit = (options) => {
    const selected = Object.keys(options).map((k) => options[k])

    return Promise.all(
      selected.map((option) => {
        return addShippingMethod({
          option_id: option.id,
          data: option.data,
        })
      })
    )
  }

  if (!cart.id) {
    return null
  }

  return (
    <CartContainer>
      {cart && (
        <CartBody>
          <Section>
            <h3>Items</h3>
            {cart.items.map((i) => (
              <LineItem key={i.id} item={i} cart={cart} />
            ))}
            <br />
            <br />
            <Row>
              <div>Subtotal</div>
              <div>{formatPrices(cart, cart.subtotal)}</div>
            </Row>
            {cart.discount_total !== 0 && (
              <Row>
                <div>Discount</div>
                <div>{formatPrices(cart, cart.discount_total)}</div>
              </Row>
            )}
          </Section>
          <Section>
            <h3>{t("shipping_address")}</h3>
            {step === "address" ? (
              <Formik
                initialValues={AddressSchema.cast(cart.shipping_address)}
                onSubmit={(values) => handleAddressSubmit(values)}
                validationSchema={AddressSchema}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <AddressSection
                      formik={formik}
                      address={cart.shipping_address}
                    />
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button type="submit">{t("next")}</Button>
                    </div>
                  </form>
                )}
              </Formik>
            ) : (
              <Row>
                <div>
                  <div>
                    {cart.shipping_address.first_name}{" "}
                    {cart.shipping_address.last_name}
                  </div>
                  {cart.shipping_address.address_1}
                  {cart.shipping_address.address_2 &&
                    `, ${cart.shipping_address.address_2}`}
                  , {cart.shipping_address.city}{" "}
                  {cart.shipping_address.postal_code},{" "}
                  {cart.shipping_address.country_code}
                </div>

                <div>
                  <u onClick={() => setStep("address")}>{t("edit")}</u>
                </div>
              </Row>
            )}
            {processingDetails && <LoadingSpinner />}
          </Section>
          <Section>
            <h3>{t("shipping_method")}</h3>
            {step === "shipping_method" ? (
              <DeliveryStep
                hideBorder
                hideTitle
                isProcessing={processingShipping}
                active={step === "shipping_method"}
                cart={cart}
                savedMethods={cart.shipping_methods}
                onSubmit={handleDeliverySubmit}
              />
            ) : (
              <Row>
                <div>
                  {cart.shipping_methods
                    .map(
                      ({ shipping_option, price }) =>
                        `${shipping_option.name} - ${formatPrices(cart, price)}`
                    )
                    .join(", ")}
                </div>
                <div>
                  <u onClick={() => setStep("shipping_method")}>{t("edit")}</u>
                </div>
              </Row>
            )}
          </Section>
          {step === "payment" ? (
            <Section>
              <PayPalScriptProvider
                options={{
                  currency: cart.currency_code.toUpperCase(),
                  "disable-funding": "card",
                  intent: PAYPAL_INTENT,
                  "client-id": PAYPAL_CLIENT_ID,
                }}
              >
                <h3 style={{ marginBottom: "0px" }}>{t("payment_method")}</h3>
                {cart.payment_sessions && cart.payment_sessions.length > 0 && (
                  <PaymentStep
                    index={3}
                    active={step === "payment"}
                    onPaymentCompleted={handlePaymentCompleted}
                    setCartInventoryCorrection={() => {}}
                  />
                )}
              </PayPalScriptProvider>
            </Section>
          ) : (
            <Section>
              <h3>{t("payment_method")}</h3>
            </Section>
          )}
        </CartBody>
      )}
    </CartContainer>
  )
}

export default PaymentLinkCheckout
